import React from 'react';
import './base.css'
import './home.css'
import Header from '../components/header';
import Footer from '../components/footer';
import backgroundImg from '../images/background.svg';
import mainImg from '../images/profile main.svg';
import outerArrows from '../images/outer arrows.svg';
import innerArrows from '../images/inner arrows.svg';
import cougarLogo from '../images/siuecougarlogo.png';
import htmlCssJs from '../images/htmlcssjs.png';
import godsEyeLogo from '../images/godseyelogo.png';
import notDoneCheck from '../images/square-check-regular.svg';
import folderImage from '../images/folder.png';

const Home = () => {
    window.addEventListener('load', () => {
        // Change welcome-header text change after 5 sec
        const welcomeHeader = document.getElementById('welcome-header');
        setTimeout(() => {
            const span = document.createElement("span");
            span.innerHTML = "_";
            welcomeHeader.innerHTML = "Scroll to continue";
            welcomeHeader.appendChild(span);
        }, 5000);
    })
    return (
        <div className='home' id='home'>
            <div className='section' id='section-home'>
                <h1 className='welcome-header' id='welcome-header'>Welcome<span>_</span></h1>
            </div>
            <Header />
            <div className='section' id='section-aboutme'>
                <h2 className='section-header' id='section-header-aboutme'>About Me<span>_</span></h2>
                <div className='section-aboutme-division'>
                    <div className='section-aboutme-division-image'>
                        <img alt='' src={backgroundImg} className='section-aboutme-division-profile-background' />
                        <img alt='' src={mainImg} className='section-aboutme-division-profile-main' />
                        <img alt='' src={outerArrows} className='section-aboutme-division-profile-outerArrows' />
                        <img alt='' src={innerArrows} className='section-aboutme-division-profile-innerArrows' />
                    </div>
                    <p className='section-aboutme-division-paragraph'>
                        {`I am an undergraduate student attending Southern Illinois University Edwardsville majoring in Computer Science
                        and minoring in business administration management. As a scholar studying in the field of Computer Science, I
                        focus primarily on programming and coding. I am proficient in JavaScript, HTML5, and CSS and have applied all
                        these languages in a personal project of mine. This project is about creating a Discord bot and building a
                        website with a UI (User Interface) to configure the bot settings. Now, to give some background on a Discord bot
                        and how I have applied my skills to create it.`
                        }</p>
                </div>
                <div className='section-aboutme-division'>
                    <div className='section-aboutme-division-image'>
                        <img alt='' src={cougarLogo} className='section-aboutme-division-image-main' />
                    </div>
                    <p className='section-aboutme-division-paragraph'>
                        {`To start, Discord is an application used for communicating with others and building communities. What makes this
        possible is the server aspect that Discord has employed; these servers allow people to have their own space for
        their community. This allows influencers, organizers, teams, and other groups to come together using the highly
        organized and sophisticated permission-based platform. Server creators can customize their server to their
        liking, but they are still limited in what they can do. For example, creating a ticket in a discord server would
        require someone with permission to create a new channel within the server and set permissions for the channel.
        To avoid doing that, server owners and administrators can invite bots to their servers to do all the heavy
        lifting. These Discord bots help to automate and autonomously make your server function. By using commands,
        server owners can make bots do whatever they want.`
                        }</p>
                </div>
                <div className='section-aboutme-division'>
                    <div className='section-aboutme-division-image'>
                        <img alt='' src={htmlCssJs} className='section-aboutme-division-image-main' />
                    </div>
                    <p className='section-aboutme-division-paragraph'>
                        {`When it comes to the application of my skills, I have created a Discord bot called Gods Eye. This bot was coded
        from the ground up and uses the Discord.Js library currently. It currently uses a couple of different
        dependencies to utilize its different modules. So what is the point of such a bot since many more bots are
        already being used? The answer to this question is simple, and that is because I want to decrease the overall
        amount of bots being used per server. To do that, I have incorporated many features from competitor bots into
        Gods Eye. Doing so has taken a lot of time and testing to make it functional and efficient for public usage. In
        doing so, I have strengthened my skills and proficiency with Javascript. But I believe all bots need a website
        to go with them to make configuring the bot for an individual's server easier. So that is where HTML, CSS, and
        JavaScript come into play.`
                        }</p>
                </div>
                <div className='section-aboutme-division'>
                    <div className='section-aboutme-division-image'>
                        <img alt='' src={godsEyeLogo} className='section-aboutme-division-image-main' id='godsEyeLogo' />
                    </div>
                    <p className='section-aboutme-division-paragraph'>
                        {`I built a website (https://godseyeofficial.xyz/) to provide information, configure Gods Eye for a user’s
        servers, and Gods Eye Staff usage. The website uses HTML/CSS/JS for the client side and NodeJS for the backend.`
                        }</p>
                </div>

            </div>
            <div className='section' id='section-myjourney'>
                <h2 className='section-header'>My Journey<span>_</span></h2>
                <div className='section-myjourney-division'>
                    <div className='section-myjourney-division-node'>
                        <p className='section-myjourney-division-node-title'>Fall 2022</p>
                        <p className='section-myjourney-division-node-description'>
                            {
                                `It was my first semester of college and when it came to organization I was doing okay. But okay was
                                not enough for what I was getting myself into. Since then my studies have been suffering little by
                                little and I have been trying to figure out what is the best way for me to stay organized. I have
                                figured out that having folders for each class will be necessary similar to high school. I also will
                                be needing seperate notebooks for each class to ensure that everything stays together and I do not
                                lose my place when taking notes. I have experienced this issue a lot when it comes to taking notes
                                for my economics class and my mathematics class. Instead of spiral notebooks I plan to use
                                composition notebooks because I like the way they look and feel. Besides organization issues I found
                                myself to be struggling working with groups for projects especially since we would never see each
                                other in person and were reliant on messages and group chats. I need to better pace out my projects
                                and setup mini goals that lead up to the completed project. Doing little by little each day or every
                                other day will allow for me to get things done without overloading myself. First time going through
                                finals has taught me a lot about myself and what I need to be doing in order to improve. When it
                                came to making my schedule I had planned out what I wanted to do for the next semester but that did
                                not go exactly how I wanted it to because come classes conflicted with each other due to CODES. So
                                my original plan to not start classes earlier than 11:00am crumbled. With finals concluded and
                                classes picked out Christmas break came and the year ended.
                                `
                            }
                        </p>
                    </div>
                    <div className='section-myjourney-division-image'>
                        <div className='section-myjourney-division-image-main' />
                    </div>
                </div>
                <div className='section-myjourney-division'>
                    <div className='section-myjourney-division-node-reversed'>
                        <p className='section-myjourney-division-node-title'>Spring 2023</p>
                        <p className='section-myjourney-division-node-description'>
                            {
                                `The start of spring semester came with its challenges and many let downs. To no surprise I still had
                                many of the same flaws that I had from last semester, but this time I everything had gotten harder. On
                                top of everything getting harder my job that I had been working since the location opened had been
                                cutting my hours which had been stressing me out. Not being able to pay for essential things put a lot
                                of stress on me which made me look for a new job. There were plenty of times where I did not feel like
                                doing anything and just wanting to lay in bed. Pretending that everything would fix itself within due
                                time is not a reality that I find myself in.
                                `
                            }
                        </p>
                    </div>
                    <div className='section-myjourney-division-image-reversed'>
                        <div className='section-myjourney-division-image-main-reversed' />
                    </div>
                </div>
                <div className='section-myjourney-division'>
                    <div className='section-myjourney-division-node'>
                        <p className='section-myjourney-division-node-title'>Fall 2023</p>
                        <p className='section-myjourney-division-node-description'>
                            {
                                `In the fall semester of 2023, I grappled with challenges that seemed to converge simultaneously.
                                Juggling a demanding course load and navigating personal upheavals.
                                As far as classes go, chemistry has never been my strong suit, and it has challenged me every time I have taken it.
                                After taking a semester of it, I decided to change my degree to a BA instead of a BS.
                                Although this will take me longer since so much time has been wasted, I plan to finish as much as possible.
                                Also, the computer science class was all right, but there were definitely things that I did not like about it.
                                Lastly, pre-calculus was a struggle, too, because I have never been good at remembering formulas for things.
                                So, having to remember so many variations of a formula would never happen.
                                Coupled with financial strain and familial responsibilities, I felt overwhelmed, struggling to find footing in a sea of uncertainty.
                                Yet, amidst the turmoil, I persisted, drawing on my resilience and determination to weather the storm, knowing that brighter days awaited.
                                `
                            }
                        </p>
                    </div>
                    <div className='section-myjourney-division-image'>
                        <div className='section-myjourney-division-image-main' />
                    </div>
                </div>
                <div className='section-myjourney-division'>
                    <div className='section-myjourney-division-node-reversed'>
                        <p className='section-myjourney-division-node-title'>Spring 2024</p>
                        <p className='section-myjourney-division-node-description'>
                            {
                                `In the spring semester of 2024, I found myself navigating through what seemed like an endless maze of challenges.
                                As the weight of coursework intensified, I battled with my mental health, grappling with anxiety and depression that seemed to cloud every aspect of my life.
                                Simple tasks became monumental hurdles, and the pressure to excel academically only exacerbated the turmoil.
                                The constant struggle to balance my studies with self-care felt like an uphill battle, and each setback seemed to deepen the abyss of despair.
                                Despite reaching out for support and seeking help, the journey towards healing felt like an arduous uphill climb.
                                Yet, amid the darkness, I clung to the flicker of hope, knowing that with each passing day, I was one step closer to finding the light again.
                                `
                            }
                        </p>
                    </div>
                    <div className='section-myjourney-division-image-reversed'>
                        <div className='section-myjourney-division-image-main-reversed' />
                    </div>
                </div>
                <div className='section-myjourney-division'>
                    <div className='section-myjourney-division-node'>
                        <p className='section-myjourney-division-node-title'>Fall 2024</p>
                        <p className='section-myjourney-division-node-description'>
                            {
                                `
                                Coming Soon
                                `
                            }
                        </p>
                    </div>
                    {/* <div className='section-myjourney-division-image'>
                        <div className='section-myjourney-division-image-main' />
                    </div> */}
                </div>

            </div>
            <div className='section' id='section-mygoals'>
                <h2 className='section-header'>My Goals<span>_</span></h2>
                <ul className='section-mygoals-list'>
                    <li className='section-mygoals-list-item'>
                        <div className='section-mygoals-list-item-check'>
                            <img src={notDoneCheck} alt='' className='section-mygoals-list-item-check-image' />
                        </div>
                        <div className='section-mygoals-list-item-goal'>
                            <p className='section-mygoals-list-item-goal-name'>
                                I want to better analyze texts and academic writing
                            </p>
                            <p className='section-mygoals-list-item-goal-description'>
                                As I work on collaborative projects I will compare my analysis with someone else to see another perspective. This will allow me to see the subject from another viewpoint.
                            </p>
                            <p className='section-mygoals-list-item-goal-progress'>
                                80% Complete
                            </p>
                        </div>
                    </li>
                    <li className='section-mygoals-list-item'>
                        <div className='section-mygoals-list-item-check'>
                            <img src={notDoneCheck} alt='' className='section-mygoals-list-item-check-image' />
                        </div>
                        <div className='section-mygoals-list-item-goal'>
                            <p className='section-mygoals-list-item-goal-name'>
                                I want to use strategies effectively and recognize when strategies are used in writing
                            </p>
                            <p className='section-mygoals-list-item-goal-description'>
                                As I read I will use examples of rhetorical strategies to point out their usages. Then when I am writing I will use as many rhetorical strategies when appropriate.
                            </p>
                            <p className='section-mygoals-list-item-goal-progress'>
                                80% Complete
                            </p>
                        </div>
                    </li>
                    <li className='section-mygoals-list-item'>
                        <div className='section-mygoals-list-item-check'>
                            <img src={notDoneCheck} alt='' className='section-mygoals-list-item-check-image' />
                        </div>
                        <div className='section-mygoals-list-item-goal'>
                            <p className='section-mygoals-list-item-goal-name'>
                                I want to create eye-catching, mind-bending writings that not only twist your perspective but also project a new mental image.
                            </p>
                            <p className='section-mygoals-list-item-goal-description'>
                                As I write and create projects I will add as many images, audio tracks, and videos to present a better mental image to the audience.
                            </p>
                            <p className='section-mygoals-list-item-goal-progress'>
                                80% Complete
                            </p>
                        </div>
                    </li>
                    <li className='section-mygoals-list-item'>
                        <div className='section-mygoals-list-item-check'>
                            <img src={notDoneCheck} alt='' className='section-mygoals-list-item-check-image' />
                        </div>
                        <div className='section-mygoals-list-item-goal'>
                            <p className='section-mygoals-list-item-goal-name'>
                                I want to sound more professional when giving speeches about important topics. I also want to be a little more organized when it comes to planning things out.
                            </p>
                            <p className='section-mygoals-list-item-goal-description'>
                                As I am planning, I will make sure that if I were to give someone else my plan,, they would understand it.
                            </p>
                            <p className='section-mygoals-list-item-goal-progress'>
                                80% Complete
                            </p>
                        </div>
                    </li>
                    <li className='section-mygoals-list-item'>
                        <div className='section-mygoals-list-item-check'>
                            <img src={notDoneCheck} alt='' className='section-mygoals-list-item-check-image' />
                        </div>
                        <div className='section-mygoals-list-item-goal'>
                            <p className='section-mygoals-list-item-goal-name'>
                                I will work harder to learn and try new methods to make my work more visually pleasing and easier to understand or use.
                            </p>
                            <p className='section-mygoals-list-item-goal-description'>
                                As I am creating projects or doing work, I will use the internet to search for new ways to do things and tricks.
                            </p>
                            <p className='section-mygoals-list-item-goal-progress'>
                                80% Complete
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='section' id='section-myeducation'>
                <h2 className='section-header'>My Education<span>_</span></h2>
                <div className='section-myeducation-container'>
                    <div className="section-myeducation">
                        <div className="section-myeducation-card" id="major-card">
                            <div className="center-vert section-myeducation-card-header">
                                <h2 className="section-myeducation-card-header-text">
                                    Major
                                </h2>
                            </div>
                            <div className="section-myeducation-card-spacer"></div>
                            <img alt='' src={folderImage} className="section-myeducation-card-image" />
                            <div className=" section-myeducation-card-description">
                                <h2 className="section-myeducation-card-description-text">
                                    I chose Computer Science because I have always been intrigued by technology and I want to be rich
                                    one day and want for nothing. So that means having a family, stable occupation, and a comfortable
                                    lifestyle.
                                </h2>
                            </div>
                        </div>
                        <div className="section-myeducation-card" id="career-card">
                            <div className="center-vert section-myeducation-card-header">
                                <h2 className="section-myeducation-card-header-text">
                                    Career Interest
                                </h2>
                            </div>
                            <div className="section-myeducation-card-spacer"></div>
                            <img alt='' src={folderImage} className="section-myeducation-card-image" />
                            <div className=" section-myeducation-card-description">
                                <h2 className="section-myeducation-card-description-text">
                                    Once I graduate with my degree in Computer Science I plan to already have my business off the ground
                                    and I plan to continue developing the business. After one part of the business becomes stable I plan
                                    to expand into different areas like servers and hosting. I plan to be the best at everything
                                    possible when dealing with online services. My goal is to become one of the fortune 500 and make a
                                    serious impact on the world.
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-myeducation">
                        <div className="section-myeducation-card" id="courses-completed-card">
                            <div className="center-vert section-myeducation-card-header">
                                <h2 className="section-myeducation-card-header-text">
                                    Completed Courses
                                </h2>
                            </div>
                            <div className="section-myeducation-card-spacer"></div>
                            <img alt='' src={folderImage} className="section-myeducation-card-image" />
                            <div className=" section-myeducation-card-description">
                                <ul className="section-myeducation-card-description-list">
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            College Algebra: <br /><br /> My time in this class I learned many things that I was not taught
                                            in
                                            high school and was a refresher of everything I learned while in highschool.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Risk and Crisis Communication:<br /><br /> I learned the effects of reporting on serious
                                            situations like
                                            covid-19. I also learned some ways to go about communicating serious potenial chaos inducing
                                            information to the public.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Principles of Microeconomics:<br /><br /> I learned that there is a lot more than just ordering
                                            more of
                                            something when it comes to how businesses are ran. I also was enlightened on the ways
                                            businesses make descisions such as what prices to take or to set. Overall a very informative
                                            class but it was really boring sitting there for an 75 minutes listening to an instructor
                                            lecture slowly on the topics.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Introduction to Chemistry:<br /><br /> I struggled with this class because chemistry is not
                                            really my
                                            thing and somehow I passed which I am grateful for. Would I do it again, probably not.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Research Team I:<br /><br /> I learned a lot about team work and what things work and what
                                            things do not
                                            work. It was a good learning experience that allowed me to reflect on my own flaws when it
                                            comes to team work.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Transdisciplinary Communication:<br /><br /> I did not learn too much because the class was
                                            mostly about
                                            different ways to communicate using the internet and digital formats. Which I have been
                                            doing for years on end and going through covid just taught me a lot as well.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Research Team II:<br /><br /> While continuing to work more on team work I have been completing
                                            projects
                                            and conversating with my peers about future plans regarding our community partner.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Research and Systems Thinking:<br /><br /> This class has taught me a lot about the fundamentals
                                            of
                                            researching which was previously introduced in research teams. This class went a little more
                                            in depth with methods and thought processes behind why research is done and how to conduct
                                            the planning of research.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Concepts of Computer Science:<br /><br /> I had gained credit for this class via an AP exam.
                                            During my time in high school I had been teaching myself coding and so when they introduced
                                            a computer
                                            science class at my high school I signed up. I thought I was going to learn something new in
                                            this class but it was more like busy work because I had already learned how to do
                                            everything. So I became more like a teacher's assistant than a student since I was helping
                                            other students more often then naught.
                                        </h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="section-myeducation">
                        <div className="section-myeducation-card" id="courses-future-card">
                            <div className="center-vert section-myeducation-card-header">
                                <h2 className="section-myeducation-card-header-text">
                                    Future Courses
                                </h2>
                            </div>
                            <div className="section-myeducation-card-spacer"></div>
                            <img alt='' src={folderImage} className="section-myeducation-card-image" />
                            <div className=" section-myeducation-card-description">
                                <ul className="section-myeducation-card-description-list">
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Community Engagement with Science:<br /><br /> I hope to learn some ways that I can better
                                            engage with my community by using my current skills to improve something.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            General Chemistry:<br /><br /> I hope to pass this class without struggling as much as I did
                                            last time.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            General Chemistry Lab:<br /><br /> I hope to be able to do everything within the lab and do it
                                            right to recieve the best grade possible.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Introduction to Computing I:<br /><br /> I hope to learn something new that will help me reach
                                            my goal.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Introduction to Sociology:<br /><br /> I hope to learn some social skills that will grant me
                                            greater insight into what people might do or see things. Hopefully I can use the things I
                                            learn in my business pursuits.
                                        </h2>
                                    </li>
                                    <li className="section-myeducation-card-description-list-item">
                                        <h2 className="section-myeducation-card-description-text">
                                            Precalculus Mathematics with Trigonometry:<br /><br />I hope I can just get through this class
                                            without stressing myself out due to assignments and all the functions that math has.
                                        </h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className='section' id='section-mywork'>
                <h2 className='section-header'>My Work<span>_</span></h2>

            </div> */}
            <Footer />
        </div>
    )
}

export default Home;